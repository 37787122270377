import React, { useState, useEffect } from "react";
import dp from "../assets/images/dp.png";
import appLogo from "../assets/images/logo.png";
import FullScreenButton from "./FullScreenButton";
import { useWindowSize } from "../hook/useWindowSize";
import { checkIsSubscribed, openInNewTab } from "../constant/Utils";
import Parse from "parse";
import { useCookies } from "react-cookie";

const Header = ({ showSidebar }) => {
  const [cookies] = useCookies();
  const { width } = useWindowSize();
  let applogo = appLogo || "";
  let username = localStorage.getItem("userName");
  const image = localStorage.getItem("profileImg") || dp;
  const [isOpen, setIsOpen] = useState(false);
  const [plan, setPlan] = useState("");

  useEffect(() => {
    checkSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function checkSubscription() {
    const res = await checkIsSubscribed();
    if (res?.isSubscribe) {
      const plan =
        res.plan === "team-weekly" ||
        res.plan === "team-yearly" ||
        res.plan === "teams-monthly" ||
        res.plan === "teams-yearly" ||
        res.plan === "enterprise-monthly" ||
        res.plan === "enterprise-yearly";
      const planName = plan ? "team" : "pro";
      setPlan(planName);
    }
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  //handle to close profile drop down menu onclick screen
  useEffect(() => {
    const closeMenuOnOutsideClick = (e) => {
      if (isOpen && !e.target.closest("#profile-menu")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", closeMenuOnOutsideClick);

    return () => {
      // Cleanup the event listener when the component unmounts
      document.removeEventListener("click", closeMenuOnOutsideClick);
    };
  }, [isOpen]);

  const closeDropdown = () => {
    setIsOpen(false);
    Parse.User.logOut();
    localStorage.clear();
    const getMainDomain = cookies?.main_Domain;
    if (getMainDomain) {
      window.open(getMainDomain, "_self");
    }
  };

  return (
    <div className="op-navbar bg-base-100 shadow">
      <div className="flex-none">
        <button
          className="op-btn op-btn-square op-btn-ghost focus:outline-none hover:bg-transparent op-btn-sm no-animation"
          onClick={showSidebar}
        >
          <i className="fa-light fa-bars text-xl text-base-content"></i>
        </button>
      </div>
      <div className="flex-1 ml-2">
        <div className="h-[25px] md:h-[40px] w-auto overflow-hidden">
          <img
            className="object-contain h-full w-auto"
            src={applogo}
            alt="img"
          />
        </div>
      </div>
      <div id="profile-menu" className="flex-none gap-2">
        {plan && (
          <div
            className={`${
              plan === "team" ? "text-xs" : "text-base"
            } uppercase select-none  w-[35px] h-[35px] bg-white rounded-full ring-[1px] ring-offset-2 ring-[#002862] text-[#002862] font-semibold flex items-center justify-center`}
          >
            {plan}
          </div>
        )}
        <div>
          <FullScreenButton />
        </div>
        {width >= 768 && (
          <div
            onClick={toggleDropdown}
            className="cursor-pointer w-[35px] h-[35px] rounded-full ring-[1px] ring-offset-2 ring-gray-400 overflow-hidden"
          >
            <img
              className="w-[35px] h-[35px] object-contain"
              src={image}
              alt="img"
            />
          </div>
        )}
        {width >= 768 && (
          <div
            onClick={toggleDropdown}
            className="cursor-pointer text-base-content text-sm"
          >
            {username && username}
          </div>
        )}
        <div className="op-dropdown op-dropdown-end" id="profile-menu">
          <div
            tabIndex={0}
            role="button"
            className="op-btn op-btn-ghost op-btn-xs w-[10px] h-[20px] hover:bg-transparent"
          >
            <i
              tabIndex={0}
              role="button"
              onClick={toggleDropdown}
              className="fa-light fa-angle-down text-base-content"
            ></i>
          </div>
          <ul
            tabIndex={0}
            className="mt-4 z-[1] p-2 shadow op-menu op-menu-sm op-dropdown-content text-base-content bg-base-100 rounded-box w-52"
          >
            <li onClick={() => openInNewTab("https://docs.opensignlabs.com")}>
              <span>
                <i className="fa-light fa-book"></i> Docs
              </span>
            </li>
            <li onClick={closeDropdown}>
              <span>
                <i className="fa-light fa-arrow-right-from-bracket"></i> Log Out
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
